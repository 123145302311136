.container {
   display: flex;
   flex-direction: column;
   margin: 2% 3%;
}

.userContent {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px;
}