.activityContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin {
  top: 50%;
  right: 50%;
  left: 50%;
  bottom: 50%;
}
