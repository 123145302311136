.container {
  background-image: url(../../assets/images/LoginLucaBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100%;
}

.logo {
  margin-top: 40px;
  margin-left: 48px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.title {
  color: #333333;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 32px;
}

.subTitle {
  color: #000000;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 16px;
}

.formContainer {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.formItem {
  margin-top: 10px;
}

.formText {
  color: #333333;
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
  margin-bottom: 4px;
}

.submitButton {
  background-color: #ffba31;
  width: 458px;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Poppins-Semibold", sans-serif;
  margin-top: 24px;
  cursor: pointer;
  border: none;
}

.formFields {
  width: 458px;
  height: 40px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
}

.formFieldsError {
  width: 458px;
  padding: 8px;
  border: 2px solid #f44336ae;
  border-radius: 5px;
  margin-bottom: 10px;
}

.clientContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 20px;
}

.clientTitle {
  color: #888da6;
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
}

.clientTitleTwo {
  color: #888da6;
  font-size: 14px;
  font-family: "Poppins-Regular", sans-serif;
  margin-top: 8px;
}

.linkText {
  color: #3843d0;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}

.helpContainer {
  background: #ffffff;
  border: 1px solid #f3f3f6;
  border-radius: 12px;
  width: 458px;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  padding: 24px;
}

.helpTitle {
  color: #4c4f63;
  font-size: 14px;
  font-family: "Poppins-Bold", sans-serif;
}

.helpSubTitle {
  color: #4c4f63;
  font-size: 12px;
  font-family: "Poppins-Regular", sans-serif;
}

.helpSubTitleBlue {
  color: #3843d0;
  font-size: 12px;
  font-family: "Poppins-Bold", sans-serif;
}

.error {
  color: #f44336;
  margin-bottom: 1%;
}

.forgotPasswordText {
  color: #3843d0;
  font-family: "Poppins-Semibold", sans-serif;
  font-size: 14px;
}
