.formFieldSelect {
  width: 100%;
  padding: 8px 10px 8px 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.error {
  color: #f44336;
  margin-bottom: 1%;
}

.containerDAD {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.containerDADColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.columBlockItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.columnBlockContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.containerBlockItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rowColumnBlockItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rowColumnBlockContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.containerGroupColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.rowBlockGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.titleBlcokContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.textTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}

.columGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.columGroupSeparate {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.rowInBlockItem {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.containerBlockGroup {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
