@font-face {
    font-family: "Popppins-Regular";
    src: url("../src/assets/fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../src/assets/fonts/Poppins-Bold.ttf");
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url("../src/assets/fonts/Poppins-SemiBold.ttf");
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 5px;
    border: none;
}

:placeholder {
    color: lightcyan;
}

.ant-input:focus {
    border: 2px solid rgb(37, 37, 189) !important;
    transition: none !important;
    animation-duration: 0s !important;
    outline: 0 !important;
    -webkit-box-shadow: none; 
    box-shadow: none!important;
}

.ant-select-focused {
    border: 2px solid rgb(37, 37, 189) !important;
}

html {
    --antd-wave-shadow-color: none !important;
}
