#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.siteLayout {
  padding: 24;
  min-height: 80vh;
  height: "32px";
  margin: 16;
  /* background: #f0f2f5; */
}
/* padding: 24,
//   minHeight: 360,
//   height: "32px",
//   margin: "16px",
//   background: "#f0f2f5", */
