.formFields {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
}

.formFieldsError {
  width: 100%;
  padding: 8px;
  border: 2px solid #f44336ae;
  border-radius: 5px;
  margin-bottom: 3px;
}

.error {
  color: #f44336;
  margin-bottom: 1%;
}
