.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.containerImageNotif {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  margin-left: 40px;
}

.containerAudio {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.activity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.containerPdf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
}

.activityPdf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.containerDADQ {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: 1px solid #5067DC;
  color: #5067DC;
  padding: 8px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: underline;
}

.fileInput {
  display: none;
}