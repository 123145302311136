.container {
  display: flex;
  flex-direction: column;
  margin: 2% 3%;
}

.newSubjectsContainer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px;
}
