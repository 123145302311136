.container {
    display: flex;
    flex-direction: column;
    margin: 2% 3%;
    color: #313435;
  }
  
  .formContainer {
    padding: 10px 20px;
    border: 1px solid #ced4da;
  }
  
  h1 {
    font-family: "Popppins-Regular";
    font-size: 1.3125rem;
  }
  
  select {
    padding: 10px;
    padding-right: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
  }
  
  label {
    color: gray;
    font-family: "Popppins-Regular";
  }
  .containerInput {
    display: flex;
    flex-direction: row;
    margin: 2% 0;
    justify-content: space-between;
  }
    .formFields {
      width: 60%;
      padding: 8px;
      border: 1px solid #ced4da;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  
  .formFieldSelect {
    width: 100%;
    padding: 8px 10px 8px 8px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .formFieldsError {
    width: 100%;
    padding: 8px;
    border: 2px solid #f44336ae;
    border-radius: 5px;
    margin-bottom: 3px;
  }
  
  .formBtn {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    width: 100%;
  }
  
  .btn {
    background-color: none;
    margin-left: 10px;
  }
  
  .error {
    color: #f44336;
    margin-bottom: 1%;
  }
  
  .tags {
    width: 100%;
    border-radius: 5px;
    border: 1.5px solid #d9d9d9;
    margin-bottom: 10px !important;
  }
  
  .tagsError {
    width: 100%;
    border: 2px solid #f44336ae;
    border-radius: 5px;
  }
  
  .dynamicInput > span .icons {
    margin: 0 0.5% 0 0;
    font-size: 16px;
  }
  
  .icons :hover {
    font-size: 17px;
  }
  
  .dynamicInput > span {
    margin: 0 2% 0 0.5%;
  }
  