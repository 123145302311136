.container {
  display: flex;
  flex-direction: column;
  margin: 2% 3%;
}
.newVideosContainer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px;
}
.textDraw {
  inline-size: 150px;
  overflow-wrap: break-word;
  margin-left: 20%;
}
