.formBtn {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}

.btn {
  /* margin-left: 10px; */
  background-color: none;
}
